import React, { useMemo, useState, useEffect, useRef, createRef } from "react";
import Modal from "react-modal";
import Sidebar from "./Sidebar";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Chart from "chart.js/auto";

Modal.setAppElement("#root");

function Subscriptions() {
  const token = localStorage.getItem("accessToken");
  const chartRef = useRef(null);
  const currentDate = useMemo(() => new Date(), []);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthsOfYear = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );
  const months = [
    { month: "2024-01", name: "January" },
    { month: "2024-02", name: "February" },
    { month: "2024-03", name: "March" },
    { month: "2024-04", name: "April" },
    { month: "2024-05", name: "May" },
    { month: "2024-06", name: "June" },
    { month: "2024-07", name: "July" },
    { month: "2024-08", name: "August" },
    { month: "2024-09", name: "September" },
    { month: "2024-10", name: "October" },
    { month: "2024-11", name: "November" },
    { month: "2024-12", name: "December" },
  ];
  const  [adminProfile, setAdminProfile] = useState({});
  const dayOfWeekIndex = currentDate.getDay();
  const monthIndex = currentDate.getMonth();
  const dayOfMonth = currentDate.getDate();
  const formattedDate = `${daysOfWeek[dayOfWeekIndex]}, ${monthsOfYear[monthIndex]} ${dayOfMonth}`;
  const adminData = sessionStorage.getItem("adminName");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [payerName, setPayerName] = useState("");
  const [gymNumber, setGymNumber] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [recentSub, setRecentSub] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [subscriberData, setSubscriberData] = useState([]);
  const [currentSubscriberPage, setCurrentSubscriberPage] = useState(1);
  const itemsPerPage = 6;
  const [searchTerm, setSearchTerm] = useState("");
  const [subPlan, setSubPlan] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [selectedPlanName, setSelectedPlanName] = useState("");
  const [addPlan, setAddPlan] = useState(false);
  const [planName, setPlanName] = useState("");
  const [planDuration, setPlanDuration] = useState("");
  const [planAmount, setPlanAmount] = useState("");
  const [addPlanSuccessModalIsOpen, setAddPlanSuccessModalIsOpen] =
    useState(false);
  const [getSubPlans, setGetSubPlans] = useState([]);
  const [planIdToDelete, setPlanIdToDelete] = useState(null);
  const [deleteSuccessModalIsOpen, setDeleteSuccessModalIsOpen] =
    useState(false);
  const popupRef = createRef();

  const openAddModal = () => {
    setAddPlan(true);
  };
  const closeAddModal = () => {
    setAddPlan(false);
  };

  const openModal = () => {
    setPayerName("");
    setGymNumber("");
    setAmountPaid("");
    setPaymentMethod("");
    setSubscriptionPlan("");
    setCurrentStep(1);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const subscriptionData = {
      user_id: gymNumber,
      payment_method: paymentMethod,
      subscription_plan: subscriptionPlan,
    };

    try {
      const response = await axios.post(
        "/super-admin/create-customer-subscription",
        subscriptionData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSuccessModalIsOpen(true); // Open success modal on successful submission
    } catch (error) {
      console.error("Error creating subscription:", error);
    }

    setPayerName("");
    setGymNumber("");
    setAmountPaid("");
    setPaymentMethod("");
    setSubscriptionPlan("");
    setModalIsOpen(false);
  };

  const handleAddPlanSubmit = async (e) => {
    e.preventDefault();
    const planData = {
      name: planName,
      duration: planDuration,
      amount: planAmount,
    };
  
    try {
      const response = await axios.post(
        "/super-admin/create-subscription-plan",
        planData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Reset form fields
      setPlanName("");
      setPlanDuration("");
      setPlanAmount("");
      closeAddModal(); // Close the modal after successful submission
      setAddPlanSuccessModalIsOpen(true); // Open the success modal
      
    } catch (error) {
      // Check if the error response has a data object and a message
      if (error.response && error.response.data && error.response.data.message) {
        // Custom check for the specific error message
        if (error.response.data.message === "Unauthorized to access this resource") {
          alert("You don't have permission to carry out this action");
        } else {
          // Alert the message from the server's response for other cases
          alert(`Error: ${error.response.data.message}`);
        }
      } else {
        // Fallback in case there's no message field in the response
        alert("An unexpected error occurred. Please try again.");
      }
  
      console.error("Error creating subscription plan:", error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const fetchAdminProfileData = async () => {
      try {
        const config = { headers: { Authorization: `Bearer ${token}` } };
        const response = await axios.get("/super-admin/get-admin-profile-data", config);
        console.log(response.data.message.admin.role.id);
        setAdminProfile(response.data.message.admin);  // Set admin profile data
      } catch (error) {
        console.error("API Error:", error);
      }
    };
  
    fetchAdminProfileData();  // Call the API
  }, []);
  
  

  const handleNextStep = (event) => {
    event.preventDefault();
    setCurrentStep(2);
  };

  const handleBackStep = () => {
    setCurrentStep(1);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  const fetchSubscriptionData = async () => {
    try {
      setLoading(true);

      const response = await axios.get("/super-admin/get-subscription-data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data.message;
      const monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      // Extracting net subscribers by month
      const netSubscribersByMonth = Array.from({ length: 12 }, (_, index) => {
        const monthData = data.net_subscribers_by_month.find(
          (item) => item.month === index + 1
        );
        return {
          x: monthsOfYear[index],
          y: monthData ? monthData.net_subscribers : 0,
        };
      });

      // Extracting net cancellations by month
      const netCancellationsByMonth = Array.from({ length: 12 }, (_, index) => {
        const monthData = data.net_cancellation_by_month.find(
          (item) => item.month === index + 1
        );
        return {
          x: monthsOfYear[index],
          y: monthData ? monthData.net_subscribers : 0,
        };
      });

      // Render the chart
      if (chartRef.current) {
        chartRef.current.destroy(); // Destroy the previous chart instance
      }

      const ctx = document.getElementById("subscriptionChart").getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, "rgba(122, 176, 255, 0.5)"); // Lighter shade of blue
      gradient.addColorStop(1, "rgba(255, 255, 255, 0.5)"); // White

      const gradientCancellation = ctx.createLinearGradient(0, 0, 0, 400);
      gradientCancellation.addColorStop(0, "rgba(255, 99, 132, 0.5)"); // Original red color
      gradientCancellation.addColorStop(1, "rgba(255, 255, 255, 0.5)"); // White

      chartRef.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: monthsOfYear,
          datasets: [
            {
              label: "Net Subscribers",
              data: netSubscribersByMonth,
              borderColor: "#2A6FF1",
              backgroundColor: gradient,
              fill: true,
              pointStyle: "circle", // Example of changing point style
            },
            {
              label: "Net Cancellations",
              data: netCancellationsByMonth,
              borderColor: "#DE6B48",
              backgroundColor: gradientCancellation,
              fill: true,
              pointStyle: "circle", // Example of changing point style
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false, // This will remove the vertical lines
              },
              ticks: {
                font: {
                  size: 12, // Adjust the font size
                  family: "Geist", // Adjust the font family
                  color: "#737373", // Adjust the font color
                },
                // Adjust padding if needed
                padding: 10,
              },
            },
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Number of Subscribers",
                font: {
                  size: 12,
                  weight: "bold",
                  family: "Geist",
                },
                color: "#737373",
                padding: {
                  top: 10,
                  bottom: 10,
                },
              },
              ticks: {
                font: {
                  size: 12,
                  family: "Geist",
                  color: "#737373",
                },
                padding: 10, // Adjust the padding here for y-axis labels
              },
            },
          },
          plugins: {
            legend: {
              position: "top",
              align: "end",
              labels: {
                font: {
                  size: 14,
                  family: "Geist",
                  padding: 100,
                  usePointStyle: true,
                  pointStyle: "circle",
                },
                color: "#010101",
              },
            },
          },
        },
      });

      setLoading(false);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          "/super-admin/get-subscription-plans",
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const plans = response.data.message?.subscription_plans ?? [];
        if (Array.isArray(plans)) {
          setGetSubPlans(plans);
        } else {
          console.error("Subscription plans response is not an array:", plans);
        }
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionPlans();
  }, [token]);

  useEffect(() => {}, [getSubPlans]);

  const handleSubscriptionPlanChange = (e) => {
    const selectedPlanId = Number(e.target.value);
    const selectedPlan = getSubPlans.find((plan) => plan.id === selectedPlanId);
    setSubscriptionPlan(selectedPlanId);
    if (selectedPlan) {
      setAmountPaid(selectedPlan.amount);
      setSelectedPlanName(selectedPlan.name); // Add this line to store the selected plan name
    } else {
      setAmountPaid("");
      setSelectedPlanName("");
    }
  };

  useEffect(() => {
    const fetchRecentSubscriptions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/super-admin/get-recent-subscriptions?page=${currentPage}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setRecentSub(response.data.message.data);
        setTotalPages(response.data.message.last_page);
      } catch (error) {
        console.error("Error fetching recent subscriptions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentSubscriptions();
  }, [currentPage]);

  const filteredSubscriptions = recentSub.filter(
    (sub) =>
      sub.payer_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.subscription_plan.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.payment_method.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sub.tnx_ref.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleExportCSV = () => {
    const csvData = [
      [
        "Invoice number",
        "Name",
        "Amount",
        "Payment date",
        "Subscription Plan",
        "Payment Method",
      ],
      ...filteredSubscriptions.map((sub) => [
        sub.tnx_ref,
        sub.payer_name,
        sub.amount,
        sub.date,
        sub.subscription_plan,
        sub.payment_method,
      ]),
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((e) => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "subscriptions.csv");
    document.body.appendChild(link);
    link.click();
  };

  const [currentPlanPage, setCurrentPlanPage] = useState(1);
  const plansPerPage = 5;
  const totalPlanPages = Math.ceil(getSubPlans.length / plansPerPage);

  const goToPlanPage = (page) => {
    setCurrentPlanPage(page);
  };

  const [optionsPopupOpen, setOptionsPopupOpen] = useState(null);

  const openOptionsPopup = (index) => {
    setOptionsPopupOpen(optionsPopupOpen === index ? null : index);
  };

  const closeOptionsPopup = () => {
    setOptionsPopupOpen(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeOptionsPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const closeConfirmModal = () => {
    setConfirmDeleteModal(false);
  };

  const deletePlan = async () => {
    try {
      const response = await fetch(
        `/super-admin/subscription-plan/${planIdToDelete}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        setGetSubPlans(
          getSubPlans.filter((plan) => plan.id !== planIdToDelete)
        ); // Update the plans state
        closeConfirmModal();
        setDeleteSuccessModalIsOpen(true);
      } else {
        console.error("Failed to delete plan");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [editModal, setEditModal] = useState(false);
  const [EditSuccessModal, setEditSuccessModal] = useState(false);

  const EditSuccessModalIsClosed = () => {
    setEditSuccessModal(false);
  };

  const [planDetails, setPlanDetails] = useState({
    name: "",
    duration_in_days: "",
    amount: "",
  });

  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const editModalIsOpen = (plan) => {
    setPlanDetails({
      name: plan.name || "",
      duration_in_days: plan.duration_in_days || "",
      amount: plan.amount || "",
    });
    setEditModal(true);
    setOptionsPopupOpen(false);
  };

  const editModalIsClose = () => {
    setEditModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlanDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault(); // Ensure event is handled correctly

    if (!selectedPlanId) {
      console.error("Selected plan ID is missing.");
      return;
    }

    // Construct updatedData with all fields
    const updatedData = {
      plan_id: selectedPlanId,
      name: planDetails.name || "",
      duration_in_days: planDetails.duration_in_days || "",
      amount: planDetails.amount || "",
    };

    try {
      const response = await axios.post(
        "/super-admin/edit-subscription-plans",
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEditModal(false);
      setEditSuccessModal(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      console.error("Error updating plan:", errorMessage);
      alert(errorMessage);
    }
  };


  useEffect(() => {
    const fetchMonthlySubscribers = async (dateRange) => {
      try {
        const response = await axios.get(
          `/super-admin/filter-monthly-subscribers?date_range=${dateRange}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data.message;
      } catch (error) {
        console.error(`Error fetching data for ${dateRange}:`, error.message);
        return null;
      }
    };

    const fetchAllMonths = async () => {
      try {
        const results = await Promise.all(
          months.map(async (month) => {
            const result = await fetchMonthlySubscribers(month.month);
            return {
              ...month,
              subscription_this_month: result
                ? result.subscription_this_month
                : "N/A",
              inactive_subscription_this_month: result
                ? result.inactive_subscription_this_month
                : "N/A",
            };
          })
        );
        setSubscriberData(results);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchAllMonths();
  }, [token]);

  // Memoize the current data to avoid unnecessary re-renders
  const currentSubscriberData = useMemo(() => {
    const indexOfLastItem = currentSubscriberPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return subscriberData.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentSubscriberPage, subscriberData]);

  // Calculate the total number of pages
  const totalSubscriberPages = useMemo(
    () => Math.ceil(subscriberData.length / itemsPerPage),
    [subscriberData.length]
  );

  // Pagination control handlers for this table only
  const nextSubscriberPage = () => {
    if (currentSubscriberPage < totalSubscriberPages) {
      setCurrentSubscriberPage((prevPage) => prevPage + 1);
    }
  };

  const prevSubscriberPage = () => {
    if (currentSubscriberPage > 1) {
      setCurrentSubscriberPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="dashboard container-fluid">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display container-fluid">
        <div className="top-bar container-fluid">
          <div className="top-bar-info">
            <p id="date">{formattedDate}</p>
            <b>
              <p>Welcome back {adminData}</p>
            </b>
          </div>
          <div className="top-bar-button">
            <button onClick={openAddModal}>
              <img
                src={`${process.env.PUBLIC_URL}/images/add-plan.png`}
                alt=""
                style={{ height: "15px" }}
              />{" "}
              Add subscription plan
            </button>
            <button onClick={openModal}>
              <img
                src={`${process.env.PUBLIC_URL}/images/add-subscription.png`}
                alt=""
              />{" "}
              Add payment
            </button>
          </div>
        </div>
        <div className="main-dashboard container-fluid sub-dashboard">
          <div className="subscription-graph">
            {loading ? (
              <div className="spinner" style={{ marginTop: "50px" }}></div>
            ) : (
              <canvas id="subscriptionChart"></canvas>
            )}
          </div>

          <div className="recent-subs">
            <h2>Recent Subscriptions</h2>
            <div className="main-settings staff-items sub-items">
              <div className="search">
                <input
                  type="text"
                  placeholder="Search fields"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="export">
                <button onClick={handleExportCSV}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/export-icon.png`}
                    alt=""
                  />{" "}
                  Export
                </button>
              </div>
            </div>
            <div className="show-admin-table" style={{ margin: "0" }}>
              <table className="admin-table">
                <thead>
                  <tr>
                    <th>Invoice number</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Payment date</th>
                    <th>Subscription Plan</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6">
                        <div className="spinner"></div>
                      </td>
                    </tr>
                  ) : (
                    filteredSubscriptions.map((sub, index) => (
                      <tr key={index}>
                        <td>{sub.tnx_ref}</td>
                        <td>{sub.payer_name}</td>
                        <td>₦{sub.amount}</td>
                        <td>
                          {new Date(sub.date).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })}
                        </td>
                        <td>{sub.subscription_plan}</td>
                        <td>{sub.payment_method}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              {/* Pagination controls */}
              <div className="pagination staff-pagination">
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <div>
                  <button
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="sub-plan-table ">
            <h2>Subscription Plans</h2>
            <div
              className="show-admin-table"
              style={{ margin: "0", marginBottom: "30px" }}
            >
              <table className="admin-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Duration</th>
                    <th>Amount</th>
                    <th>Creation Date</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6">
                        <div className="spinner"></div>
                      </td>
                    </tr>
                  ) : (
                    getSubPlans
                      .slice(
                        (currentPlanPage - 1) * plansPerPage,
                        currentPlanPage * plansPerPage
                      )
                      .map((plan, index) => (
                        <tr key={index}>
                          <td>{plan.id}</td>
                          <td>{plan.name}</td>
                          <td>{plan.duration_in_days}</td>
                          <td>₦{plan.amount}</td>
                          <td>
                            {new Date(plan.created_at).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td>
                            <div className="options-menu">
                              <img
                                alt=""
                                src={`${process.env.PUBLIC_URL}/images/horizontal-menu.png`}
                                onClick={() => setOptionsPopupOpen(index)}
                              />
                              {optionsPopupOpen === index && (
                                <div
                                  className="popup"
                                  style={{ width: "150px", left: "-95px" }}
                                >
                                  <ul>
                                    <li
                                      onClick={() => {
                                        editModalIsOpen(true);
                                        setSelectedPlanId(plan.id);
                                      }}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/edit.png`}
                                      />{" "}
                                      Edit Plan
                                    </li>
                                    <li
                                      onClick={() => {
                                        setConfirmDeleteModal(true);
                                        setPlanIdToDelete(plan.id);
                                        setOptionsPopupOpen(null);
                                      }}
                                      style={{ color: "red" }}
                                    >
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/delete.png`}
                                      />{" "}
                                      Delete Plan
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>

              <div className="pagination staff-pagination">
                <span>
                  Page {currentPlanPage} of {totalPlanPages}
                </span>
                <div>
                  <button
                    onClick={() => goToPlanPage(currentPlanPage - 1)}
                    disabled={currentPlanPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => goToPlanPage(currentPlanPage + 1)}
                    disabled={currentPlanPage === totalPlanPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div className="sub-log">
          <h2>Subscription Log</h2>
          <div className="show-admin-table"
              style={{ margin: "0", marginBottom: "30px" }}>
      <table className="admin-table">
        <thead>
          <tr>
            <th>Month</th>
            <th>Total Subscribers</th>
            <th>Active Subscriptions</th>
            <th>Inactive Subscriptions</th>
          </tr>
        </thead>
        <tbody>
          {currentSubscriberData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.subscription_this_month}</td>
              <td>
                {item.subscription_this_month !== "N/A" && item.inactive_subscription_this_month !== "N/A"
                  ? item.subscription_this_month - item.inactive_subscription_this_month
                  : "N/A"}
              </td>
              <td>{item.inactive_subscription_this_month}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination staff-pagination">
      <span>Page {currentSubscriberPage} of {totalSubscriberPages}</span>
      <div>
        <button onClick={prevSubscriberPage} disabled={currentSubscriberPage === 1}>Previous</button>
        <button onClick={nextSubscriberPage} disabled={currentSubscriberPage === totalSubscriberPages}>Next</button>
        </div>
      </div>
      </div>
    </div>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Add Subscription Modal"
          className="add-admin-modal"
        >
          {currentStep === 1 && (
            <div>
              <div className="add-staff-head">
                <h2>Member details</h2>
              </div>
              <form onSubmit={handleNextStep}>
                <div className="inner-form sub-form">
                  <label>
                    Payer's Name:{" "}
                    <input
                      type="text"
                      value={payerName}
                      onChange={(e) => setPayerName(e.target.value)}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Gym Number:{" "}
                    <input
                      type="text"
                      value={gymNumber}
                      onChange={(e) => setGymNumber(e.target.value)}
                      required
                    />
                  </label>
                  <br />
                  <label>
                    Subscription Plan:{" "}
                    <select
                      value={subscriptionPlan}
                      onChange={handleSubscriptionPlanChange}
                      required
                      disabled={loading}
                    >
                      <option value="">Select Subscription Plan</option>
                      {!loading &&
                        getSubPlans.map((plan, index) => (
                          <option key={index} value={plan.id}>
                            {plan.name}
                          </option>
                        ))}
                    </select>
                  </label>
                  <br />
                  <label>
                    Amount Paid:{" "}
                    <input type="number" value={amountPaid} readOnly required />
                  </label>
                  <br />
                  <label>
                    Payment Method:{" "}
                    <select
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      required
                    >
                      <option value="">Select Payment Method</option>
                      <option value="POS Payment">POS</option>
                      <option value="Bank Transfer">Bank Transfer</option>
                    </select>
                  </label>
                  <br />
                </div>
                <div className="modal-button">
                  <button type="button" onClick={closeModal}>
                    Cancel
                  </button>
                  <input type="submit" value="Next" />
                </div>
              </form>
            </div>
          )}
          {currentStep === 2 && (
            <div className="sub-confirm">
              <div className="add-staff-head">
                <h2>Payment Overview</h2>
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/images/payment-overview.png`}
                alt=""
              />
              <div className="details-container container">
                <div className="detail">
                  <p>Payer's Name</p>
                  <b>
                    <p className="D">{payerName}</p>
                  </b>
                </div>
                <div className="detail">
                  <p>Gym Number</p>
                  <b>
                    <p className="D">{gymNumber}</p>
                  </b>
                </div>
                <div className="detail">
                  <p>Subscription Plan</p>
                  <b>
                    <p className="D">{selectedPlanName}</p>
                  </b>
                </div>
                <div className="detail">
                  <p>Payment Method</p>
                  <b>
                    <p className="D">{paymentMethod}</p>
                  </b>
                </div>
                <div className="detail">
                  <p>Amount Paid</p>
                  <b>
                    <p className="D">{amountPaid}</p>
                  </b>
                </div>
              </div>
              <div className="modal-button">
                <button type="button" onClick={handleBackStep}>
                  Back
                </button>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#0473bd", color: "white" }}
                >
                  Complete
                </button>
              </div>
            </div>
          )}
        </Modal>

        <Modal
          isOpen={successModalIsOpen}
          onRequestClose={closeSuccessModal}
          contentLabel="Success Modal"
          className="edit-success-modal"
        >
          <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
          <h2>Payment Confirmed</h2>
          <button onClick={closeSuccessModal}>Close</button>
        </Modal>

        <Modal
          isOpen={addPlan}
          onRequestClose={closeAddModal}
          contentLabel="Add Subscription"
          className="add-admin-modal"
        >
          <div className="add-staff-head">
            <h2>Add Subscription Plan</h2>
          </div>
          <form onSubmit={handleAddPlanSubmit}>
            <div className="inner-form">
              <label>
                Name:{" "}
                <input
                  type="text"
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  required
                  placeholder="Plan name"
                />
              </label>
              <br />
              <label>
                Duration:{" "}
                <input
                  type="text"
                  value={planDuration}
                  onChange={(e) => setPlanDuration(e.target.value)}
                  required
                  placeholder="Duration in days"
                />
              </label>
              <br />
              <label>
                Amount:{" "}
                <input
                  type="number"
                  value={planAmount}
                  onChange={(e) => setPlanAmount(e.target.value)}
                  required
                  placeholder="Amount in naira"
                />
              </label>
              <br />
            </div>
            <div className="modal-button">
              <button type="button" onClick={closeAddModal}>
                Cancel
              </button>
              <input type="submit" value="Add Plan" />
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={successModalIsOpen}
          onRequestClose={closeSuccessModal}
          contentLabel="Success Modal"
          className="edit-success-modal"
        >
          <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
          
          {adminProfile?.role?.id === 3 ? (
              <>
            <h2>Awaiting Payment Approval</h2>
            <p>Kindly confirm payment</p>
            </>
          ) : (
            <>
              <h2>Awaiting Payment Approval</h2>
              <p>A super admin will confirm this payment soon</p>
            </>
          )}
          
          <button onClick={closeSuccessModal}>Close</button>
        </Modal>




        <Modal
          isOpen={confirmDeleteModal}
          onRequestClose={closeConfirmModal}
          contentLabel="Delete Modal"
          className="delete-modal"
        >
          <div className="delete-modal-inner">
            <img
              src={`${process.env.PUBLIC_URL}/images/delete-danger.png`}
              alt=""
            />
            <p>Do you want to delete this Plan</p>
            <div className="delete-modal-button">
              <button className="delete-btn1" onClick={deletePlan}>
                Delete
              </button>
              <button className="delete-btn2" onClick={closeConfirmModal}>
                Back
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={deleteSuccessModalIsOpen}
          onRequestClose={() => setDeleteSuccessModalIsOpen(false)}
          contentLabel="Delete Success Modal"
          className="edit-success-modal"
        >
          <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
          <h2>Plan deleted</h2>
          <p style={{textAlign:'center'}}>Subscription Plan has been deleted successfully</p>
          <button
            type="button"
            onClick={() => setDeleteSuccessModalIsOpen(false)}
          >
            Cancel
          </button>
        </Modal>

        <Modal
          isOpen={editModal}
          onRequestClose={editModalIsClose}
          contentLabel="Edit modal"
          className="add-admin-modal"
        >
          <div className="add-staff-head">
            <h2>Edit Subscription Plan</h2>
          </div>

          <form onSubmit={handleSave}>
            <div className="inner-form">
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={planDetails.name}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Duration (in days):
                <input
                  type="number"
                  name="duration_in_days"
                  value={planDetails.duration_in_days}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Amount:
                <input
                  type="number"
                  name="amount"
                  value={planDetails.amount}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>

            <div className="modal-button">
              <button type="button" onClick={editModalIsClose}>
                Cancel
              </button>
              <input type="submit" value="Save Update" />
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={EditSuccessModal}
          onRequestClose={EditSuccessModalIsClosed}
          contentLabel="Success Modal"
          className="edit-success-modal"
        >
          <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
          <p>Subscription plan edited successfully</p>
          <button onClick={EditSuccessModalIsClosed}>Close</button>
        </Modal>

      </div>
    </div>
  );
}

export default Subscriptions;
