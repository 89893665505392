import React, { useEffect, useState, useContext, createContext } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";

// Context for admin profile data including role ID
const AdminProfileContext = createContext();

const AdminProfileProvider = ({ children }) => {
  const [adminProfileData, setAdminProfileData] = useState(
    JSON.parse(sessionStorage.getItem("adminProfileData")) || null
  );
  const [roleId, setRoleId] = useState(null); // Initialize roleId state
  const [loading, setLoading] = useState(true); // State to manage loading state

  useEffect(() => {
    const fetchAdminProfileData = async () => {
      const token = localStorage.getItem("accessToken");

      if (!sessionStorage.getItem("adminProfileData")) {
        try {
          const response = await axios.get(
            "/super-admin/get-admin-profile-data",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          sessionStorage.setItem(
            "adminProfileData",
            JSON.stringify(response.data)
          );

          sessionStorage.setItem(
            "adminName",
            JSON.stringify(response.data.message.admin.f_name)
          );
          setAdminProfileData(response.data);

          // Set roleId from adminProfileData if available
          if (
            response.data &&
            response.data.message &&
            response.data.message.admin
          ) {
            setRoleId(response.data.message.admin.role.id);
          }
        } catch (error) {
          console.error("Error fetching admin profile data:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      } else {
        // Set roleId from sessionStorage if adminProfileData is already present
        if (
          adminProfileData &&
          adminProfileData.message &&
          adminProfileData.message.admin
        ) {
          setRoleId(adminProfileData.message.admin.role.id);
        }
        setLoading(false); // Set loading to false
      }
    };

    fetchAdminProfileData();
  }, []);

  if (loading) {
    return <p>...</p>; // Optionally show a loading indicator while fetching data
  }

  return (
    <AdminProfileContext.Provider value={{ adminProfileData, roleId }}>
      {children}
    </AdminProfileContext.Provider>
  );
};

function Sidebar() {
  const location = useLocation();
  const activeLink = location.pathname;

  const { adminProfileData, roleId } = useContext(AdminProfileContext);

  const handleSignOut = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      await axios.get("/super-admin/admin/set-last-seen", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      window.location.href = "/login"; // Redirect to login page after success
      localStorage.removeItem("accessToken");
      sessionStorage.removeItem("adminProfileData");
      sessionStorage.clear();
      localStorage.clear();
    } catch (error) {
      console.error("Error setting last seen:", error);
      window.location.href = "/login"; // Redirect to login page after error
    }
  };

  // Preload images
  useEffect(() => {
    const images = [
      "frebson-logo.png",
      "admin-img.png",
      "icon-overview-active.png",
      "icon-overview-inactive.png",
      "icon-member-active.png",
      "icon-member-inactive.png",
      "icon-subscription-active.png",
      "icon-subscription-inactive.png",
      "icon-notifications-active.png",
      "icon-notifications-inactive.png",
      "icon-staff-active.png",
      "icon-staff-inactive.png",
      "icon-settings-active.png",
      "icon-settings-inactive.png",
      "icon-signout-active.png",
      "icon-signout-inactive.png",
    ];
    images.forEach((image) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}/images/${image}`;
    });
  }, []);

  const defaultProfileImage = `${process.env.PUBLIC_URL}/images/settings-avatar.png`; // Path to your default profile image

  return (
    <div className="sidebar">
      <div className="logo">
        <img
          src={`${process.env.PUBLIC_URL}/images/final-logo2.png`}
          alt="FrebsonLogo"
        />
      </div>
      <div className="profile">
        <img
          src={adminProfileData.message.admin.profile_image || defaultProfileImage}
          alt="Profile" style={{borderRadius:"50%"}}
        />
        <div className="profile-details">
          {adminProfileData &&
          adminProfileData.message &&
          adminProfileData.message.admin ? (
            <>
              <p className="name">
              {adminProfileData.message.admin.f_name} 
              {adminProfileData.message.admin.l_name !== null ? ` ${adminProfileData.message.admin.l_name}` : ''}
            </p>

              <p className="role">
                {adminProfileData.message.admin.role_id === 2 ? "Admin" : "Super Admin"}{" "}
                <span className="id">
                  ( #{adminProfileData.message.admin.id} )
                </span>
              </p>
            </>
          ) : (
            <p>Loading profile...</p>
          )}
        </div>
      </div>
      <nav className="nav-menu">
      <nav className="nav-menu">
          {activeLink === "/qr-code" ? (
            <span className="nav-item disabled">
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-overview-inactive.png`} // Use inactive image
                alt="QR Code"
              />
              QR Code
            </span>
          ) : (
            <Link
              to="/dashboard"
              className={`nav-item ${
                activeLink === "/dashboard" ? "active" : ""
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-overview-${
                  activeLink === "/dashboard" ? "active" : "inactive"
                }.png`}
                alt="Overview"
              />
              Overview
            </Link>
          )}
        </nav>

        {activeLink !== "/qr-code" && (
          <>
            <Link
              to="/member-management"
              className={`nav-item ${
                activeLink === "/member-management" ? "active" : ""
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-member-${
                  activeLink === "/member-management" ? "active" : "inactive"
                }.png`}
                alt="Member Management"
              />
              Member Management
            </Link>
            <Link
              to="/subscription"
              className={`nav-item ${
                activeLink === "/subscription" ? "active" : ""
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-subscription-${
                  activeLink === "/subscription" ? "active" : "inactive"
                }.png`}
                alt="Subscription"
              />
              Subscription
            </Link>
            <Link
              to="/notifications"
              className={`nav-item ${
                activeLink === "/notifications" ? "active" : ""
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/icon-notifications-${
                  activeLink === "/notifications" ? "active" : "inactive"
                }.png`}
                alt="Notifications"
              />
              Notifications
            </Link>
            {roleId === 3 && ( // Display only if roleId is 3
              <>
                <Link
                  to="/staff-management"
                  className={`nav-item ${
                    activeLink === "/staff-management" ? "active" : ""
                  }`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon-staff-${
                      activeLink === "/staff-management" ? "active" : "inactive"
                    }.png`}
                    alt="Staff Management"
                  />
                  Staff Management
                </Link>
                <Link
                  to="/settings"
                  className={`nav-item ${
                    activeLink === "/settings" ? "active" : ""
                  }`}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/icon-settings-${
                      activeLink === "/settings" ? "active" : "inactive"
                    }.png`}
                    alt="Settings"
                  />
                  Settings
                </Link>
              </>
            )}
          </>
        )}

        {/* Always visible Sign Out link */}
        <div
          className={`nav-item sign-out ${
            activeLink === "/sign-out" ? "active" : ""
          }`}
          onClick={handleSignOut}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/icon-signout-${
              activeLink === "/sign-out" ? "active" : "inactive"
            }.png`}
            alt="Sign Out"
          />
          Sign Out
        </div>
      </nav>
    </div>
  );
}

const App = () => {
  return (
    <AdminProfileProvider>
      <Sidebar />
    </AdminProfileProvider>
  );
};

export default App;