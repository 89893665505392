import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import Box from "./Box";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import Modal from "react-modal";

function Dashboard() {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentWeekDates, setCurrentWeekDates] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date
  const [attendanceStatus, setAttendanceStatus] = useState(
    "No User has clocked-in today"
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [barChartData, setBarChartData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [currentMonthAttendance, setCurrentMonthAttendance] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [usersPerPage] = useState(10); // Users per page
  const currentDate = useMemo(() => new Date(), []);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [membersChangeData, setMembersChangeData] = useState({
    totalMembersChange: "0",
    activeMembersChange: "0",
    inactiveMembersChange: "0",
    activeMembersChangeColor: "black",
    inactiveMembersChangeColor: "black",
  });
  const monthsOfYear = useMemo(
    () => [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    []
  );

  const dayOfWeekIndex = currentDate.getDay();
  const monthIndex = currentDate.getMonth();
  const dayOfMonth = currentDate.getDate();
  const formattedDate = `${daysOfWeek[dayOfWeekIndex]}, ${monthsOfYear[monthIndex]} ${dayOfMonth}`;
  const [currentFullMonthName, setCurrentFullMonthName] = useState("");
  const adminData = sessionStorage.getItem("adminName");
  const [number, setNumber] = useState("");
  const [grantAccess, setGrantAccess] = useState(false);
  const [clockInDetails, setClockInDetails] = useState({});
  const [clockInError, setClockInError] = useState("");

  const openOneTimeAccess = () => {
    setGrantAccess(true);
  };

  const closeOneTimeAccess = () => {
    setGrantAccess(true);
  };

  const handleAccessSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axios.post(
        '/super-admin/clock-in-user',
        { phone: number }, 
        {
          headers: {
            Authorization: `Bearer ${token}`, 
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Success:', response.data);
      setClockInDetails(response.data.message.user);
      setGrantAccess(false);
      setAccessSuccess(true);
      setNumber('');
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      setNumber('');
      setGrantAccess(false);
      setClockInError(error.response.data.message);
      setAccessFailure(true);
     
    }
  };

  const [AccessSuccess, setAccessSuccess] = useState(false);
  const [AccessFailure, setAccessFailure] = useState(false);


  const AccessSuccessIsClosed = () => {
    setAccessSuccess(false);
  };

  const AccessFailureIsClosed = () => {
    setAccessFailure(false);
  };

  const fetchMonthlyTotalMembers = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const currentDate = new Date();
      const currentMonthIndex = currentDate.getMonth();
      const previousMonthIndex =
        currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
      const yearForPreviousMonth =
        currentMonthIndex === 0
          ? currentDate.getFullYear() - 1
          : currentDate.getFullYear();

      const startOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentMonthIndex,
        1
      );
      const endOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentMonthIndex + 1,
        0
      );

      const startOfPreviousMonth = new Date(
        yearForPreviousMonth,
        previousMonthIndex,
        1
      );
      const endOfPreviousMonth = new Date(
        yearForPreviousMonth,
        previousMonthIndex + 1,
        0
      );

      const formattedStartOfCurrentMonth = startOfCurrentMonth
        .toISOString()
        .split("T")[0];
      const formattedEndOfCurrentMonth = endOfCurrentMonth
        .toISOString()
        .split("T")[0];

      const formattedStartOfPreviousMonth = startOfPreviousMonth
        .toISOString()
        .split("T")[0];
      const formattedEndOfPreviousMonth = endOfPreviousMonth
        .toISOString()
        .split("T")[0];

      const fetchMembersData = async (startDate, endDate) => {
        const response = await axios.get(`/super-admin/filter-total-members`, {
          params: { start_date: startDate, end_date: endDate },
          headers: { Authorization: `Bearer ${token}` },
        });
        return response.data.message;
      };

      const currentMonthData = await fetchMembersData(
        formattedStartOfCurrentMonth,
        formattedEndOfCurrentMonth
      );
      const previousMonthData = await fetchMembersData(
        formattedStartOfPreviousMonth,
        formattedEndOfPreviousMonth
      );

      const calculateStats = (data) => {
        const totalMembers = data.length || 0;
        const activeMembers =
          data.filter((user) => user.subscription_status === "subscribed")
            .length || 0;
        const inactiveMembers =
          data.filter((user) => user.subscription_status !== "subscribed")
            .length || 0;
        return { totalMembers, activeMembers, inactiveMembers };
      };

      const currentMonthStats = calculateStats(currentMonthData);
      const previousMonthStats = calculateStats(previousMonthData);

      const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
          return current === 0 ? 0 : 100;
        }
        return ((current - previous) / previous) * 100;
      };

      const totalMembersChange = calculatePercentageChange(
        currentMonthStats.totalMembers,
        previousMonthStats.totalMembers
      );
      const activeMembersChange = calculatePercentageChange(
        currentMonthStats.activeMembers,
        previousMonthStats.activeMembers
      );
      const inactiveMembersChange = calculatePercentageChange(
        currentMonthStats.inactiveMembers,
        previousMonthStats.inactiveMembers
      );

      const getChangeColor = (change) => (change >= 0 ? "blue" : "red");

      setMembersChangeData({
        totalMembersChange: totalMembersChange.toFixed(2),
        totalMembersChangeColor: getChangeColor(totalMembersChange),
        activeMembersChange: activeMembersChange.toFixed(2),
        activeMembersChangeColor: getChangeColor(activeMembersChange),
        inactiveMembersChange: inactiveMembersChange.toFixed(2),
        inactiveMembersChangeColor: getChangeColor(inactiveMembersChange),
      });
    } catch (error) {
      console.error("Error fetching monthly total members data:", error);
      setMembersChangeData({
        totalMembersChange: "N/A",
        activeMembersChange: "N/A",
        inactiveMembersChange: "N/A",
        totalMembersChangeColor: "black",
        activeMembersChangeColor: "black",
        inactiveMembersChangeColor: "black",
      });
    }
  };

  useEffect(() => {
    fetchMonthlyTotalMembers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = JSON.parse(sessionStorage.getItem("dashboardData"));

        if (cachedData) {
          setTotalRevenue(cachedData.totalRevenue);
          setStatsData(cachedData.statsData);
        }

        setLoading(true);

        const token = localStorage.getItem("accessToken");
        const response = await axios.get("/super-admin/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data.message;
        const stats = [
          {
            message: "Total Members",
            statNumber: data.total_members,
          },
          {
            message: "Active Members",
            statNumber: data.active_members,
          },
          {
            message: "Inactive Members",
            statNumber: data.inactive_members,
          },
        ];

        setTotalRevenue(data.total_revenue || 0);
        setStatsData(stats);
        setLoading(false);

        // Store data in sessionStorage
        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify({
            totalRevenue: data.total_revenue || 0,
            statsData: stats,
          })
        );
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Failed to fetch dashboard data");
        setLoading(false);
      }
    };

    const updateWeekDates = () => {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      const weekDates = Array.from({ length: 7 }).map((_, i) => {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        return date;
      });
      setCurrentWeekDates(weekDates);
    };

    const updateSessionStorageOnRefresh = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get("/super-admin/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = response.data.message;
        const stats = [
          {
            message: "Total Members",
            statNumber: data.total_members,
          },
          {
            message: "Active Members",
            statNumber: data.active_members,
          },
          {
            message: "Inactive Members",
            statNumber: data.inactive_members,
          },
        ];

        // Update sessionStorage with the new data
        sessionStorage.setItem(
          "dashboardData",
          JSON.stringify({
            totalRevenue: data.total_revenue || 0,
            statsData: stats,
          })
        );
      } catch (error) {
        console.error("Error updating sessionStorage:", error);
      }
    };

    // Initial fetch and update
    fetchData();
    updateWeekDates();

    // Set up beforeunload event to update sessionStorage on refresh
    window.addEventListener("beforeunload", updateSessionStorageOnRefresh);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", updateSessionStorageOnRefresh);
    };
  }, [currentDate, membersChangeData]);

  const fetchAttendanceData = async (startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    setLoading(true);

    try {
      const response = await axios.get("/super-admin/filter-attendance", {
        params: {
          start_date: startDate.toLocaleDateString("en-CA"), // YYYY-MM-DD format
          end_date: endDate.toLocaleDateString("en-CA"),     // YYYY-MM-DD format
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const attendanceData = response.data?.message || [];
      setAttendanceData(attendanceData);

      setAttendanceStatus(
        attendanceData.length > 0 ? "" : "No user has clocked-in today"
      );
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setAttendanceStatus("Failed to fetch attendance data");
      setAttendanceData([]);
    } finally {
      setLoading(false);
    }
  };

  const loadAttendanceForDate = async (date) => {
    setSelectedDate(date);
    await fetchAttendanceData(date, date);
  };

  useEffect(() => {
    // Function to fetch attendance for selected date on mount
    const fetchAttendanceForSelectedDate = async () => {
      await loadAttendanceForDate(selectedDate);
    };

    fetchAttendanceForSelectedDate(); // Initial load on mount
  }, [selectedDate]); // Dependency array ensures it runs when selectedDate changes

  // Assuming setAttendanceData and setAttendanceStatus are useState setters

  const handleCalendarClick = (date) => {
    if (!startDate) {
      setStartDate(date);
    } else if (!endDate) {
      setEndDate(date);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const handleCancelClick = () => {
    setStartDate(null);
    setEndDate(null);
    setShowCalendar(false);
  };

  const handleApplyClick = () => {
    if (startDate && endDate) {
      fetchAttendanceData(startDate, endDate);
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("accessToken");

      // Check local storage for cached chart data
      const cachedLineChartData = JSON.parse(
        localStorage.getItem("lineChartData")
      );
      const cachedBarChartData = JSON.parse(
        localStorage.getItem("barChartData")
      );

      if (cachedLineChartData && cachedBarChartData) {
        setLineChartData(cachedLineChartData);
        setBarChartData(cachedBarChartData);

        // Set current month attendance value
        setCurrentMonthAttendance(
          cachedLineChartData.datasets[0].data[currentDate.getMonth()]
        );

        // Calculate total revenue from cached data
        let totalRev = 0;
        cachedBarChartData.datasets[0].data.forEach((rev) => (totalRev += rev));
        setTotalRevenue(totalRev);

        // Set current full month name (e.g., January, February)
        setCurrentFullMonthName(monthsOfYear[currentDate.getMonth()]); // Adjust as per your monthsOfYear array

        return; // Exit function early if cached data is available
      }

      // Fetch data if not cached
      const attendancePromises = monthsOfYear.map(async (_, monthIndex) => {
        const startOfMonth = new Date(currentDate.getFullYear(), monthIndex, 1);
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          monthIndex + 1,
          0
        );
        const formattedStartDate = startOfMonth.toISOString().split("T")[0];
        const formattedEndDate = endOfMonth.toISOString().split("T")[0];

        try {
          const attendanceResponse = await axios.get(
            `/super-admin/filter-attendance?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const attendanceResult = attendanceResponse.data.message;

          return attendanceResult.length;
        } catch (error) {
          console.error(
            `Error fetching attendance data for ${
              startOfMonth.getMonth() + 1
            }/${startOfMonth.getFullYear()}:`,
            error
          );
          return 0;
        }
      });

      const revenuePromises = monthsOfYear.map(async (_, monthIndex) => {
        const startOfMonth = new Date(currentDate.getFullYear(), monthIndex, 1);
        const endOfMonth = new Date(
          currentDate.getFullYear(),
          monthIndex + 1,
          0
        );
        const formattedStartDate = startOfMonth.toISOString().split("T")[0];
        const formattedEndDate = endOfMonth.toISOString().split("T")[0];

        try {
          const revenueResponse = await axios.get(
            `/super-admin/filter-revenue-sum?start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          return revenueResponse.data.message.revenue || 0;
        } catch (error) {
          console.error(
            `Error fetching revenue data for ${
              startOfMonth.getMonth() + 1
            }/${startOfMonth.getFullYear()}:`,
            error
          );
          return 0;
        }
      });

      try {
        // Wait for all promises to resolve
        const attendanceData2 = await Promise.all(attendancePromises);
        const revenueData = await Promise.all(revenuePromises);

        // Prepare line chart data
        const lineChartData = {
          labels: monthsOfYear,
          datasets: [
            {
              label: "Number of members present",
              data: attendanceData2,
              backgroundColor: "rgba(42, 111, 241, 1)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              fill: true,
            },
          ],
        };

        // Prepare bar chart data
        const barChartData = {
          labels: monthsOfYear,
          datasets: [
            {
              label: "Generated Revenue",
              data: revenueData,
              backgroundColor: "rgba(42, 111, 241, 1)",
              borderColor: "rgba(54, 162, 235, 1)",
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        };

        // Update state with both chart data
        setLineChartData(lineChartData);
        setBarChartData(barChartData);

        // Cache line chart and bar chart data
        localStorage.setItem("lineChartData", JSON.stringify(lineChartData));
        localStorage.setItem("barChartData", JSON.stringify(barChartData));

        // Set current month attendance value
        setCurrentMonthAttendance(attendanceData2[currentDate.getMonth()]);

        // Calculate total revenue
        let totalRev = 0;
        revenueData.forEach((rev) => (totalRev += rev));
        setTotalRevenue(totalRev);

        // Set current full month name (e.g., January, February)
        setCurrentFullMonthName(monthsOfYear[currentDate.getMonth()]); // Adjust as per your monthsOfYear array
      } catch (error) {
        console.error("Error fetching monthly data:", error);
      }
    };

    fetchData(); // Initial fetch
  }, [currentDate, monthsOfYear]);

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top", // Position at the top
        align: "end", // Align to the right
        labels: {
          padding: 20, // Adjust this value to change the padding around the legend
          usePointStyle: true, // Use point style instead of default box
          pointStyle: "circle", // Choose a predefined point style
        },
      },
    },
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: "top", // Position at the top
        align: "end", // Align to the right
        labels: {
          padding: 20, // Adjust this value to change the padding around the legend
          usePointStyle: true, // Use point style instead of default box
          pointStyle: "circle", // Choose a predefined point style
        },
      },
    },
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const renderAttendanceTable = () => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = attendanceData.slice(
      indexOfFirstUser,
      indexOfFirstUser + usersPerPage
    );

    if (loading) {
      return <div className="spinner" style={{ marginTop: "50%" }}></div>;
    }

    if (attendanceData.length === 0) {
      return (
        <div className="attendance-status">
          <img
            src={`${process.env.PUBLIC_URL}/images/clock-in-img.png`}
            alt=""
          />
          <p>{attendanceStatus}</p>
        </div>
      );
    }

    const totalPages = Math.ceil(attendanceData.length / usersPerPage);

    return (
      <div className="attendance-table">
        <div className="attendance-table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Clock-In</th>
                <th>Time Spent</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.user_name}</td>
                  <td>
                    {new Date(entry.clock_in).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                  <td>{entry.hours_spent || '---'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination controls */}
          <div className="pagination">
            <div className="pagination-info">
              Page {currentPage} of {totalPages}
            </div>
            <div>
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard container-fluid">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display container-fluid" style={{paddingBottom:'20px'}}>
        <div className="top-bar container-fluid">
          <div className="top-bar-info">
            <p id="date">{formattedDate}</p>
            <b>
              <p>Welcome back {adminData}</p>
            </b>
          </div>
          <div className="top-bar-button">
          <button onClick={openOneTimeAccess} style={{background:'none', border:'2px solid #0473BD', color:'#0473BD'}}>
              <img
                src={`${process.env.PUBLIC_URL}/images/access-control.png`}
                alt=""
                style={{ height: "16px", width:'16px'}}
              />{" "}
              Provide Access
            </button>
            <Link to="/qr-code" target="_blank">
              <button>
                <img
                  src={`${process.env.PUBLIC_URL}/images/qr-button-img.png`}
                  alt=""
                />{" "}
                Generate QR Code
              </button>
            </Link>
          </div>
        </div>
        <div className="stats-row">
          {statsData.map((stat, index) => (
            <Box
              key={index}
              message={stat.message}
              statNumber={stat.statNumber}
            />
          ))}
        </div>
        <div className="main-dashboard container-fluid">
          <div className="graphs">
            <div className="bar-graph">
              <div className="revenue-header">
                <img
                  src={`${process.env.PUBLIC_URL}/images/total-revenue-img.png`}
                  alt=""
                />
                <div className="revenue-text">
                  <p>Total Revenue generated</p>
                  <h2>₦{totalRevenue.toLocaleString()}</h2>
                </div>
              </div>
              <div className="bar-chart-container container-fluid">
                {barChartData ? (
                  <Bar data={barChartData} options={barChartOptions} />
                ) : (
                  <div className="spinner" style={{ marginTop: "50px" }}></div>
                )}
              </div>
            </div>
            <div className="line-chart container-fluid">
              <div className="attendance-head">
                <img
                  src={`${process.env.PUBLIC_URL}/images/total-attendance-img.png`}
                  alt=""
                />
                <div className="attendance-text2">
                  <p>Total Attendance in {currentFullMonthName}</p>
                  <h2>
                    {currentMonthAttendance}{" "}
                    <span
                      style={{
                        color: "#666666",
                        fontSize: "14px",
                        fontWeight: "normal",
                      }}
                    >
                      Members
                    </span>
                  </h2>
                </div>
              </div>
              <div className="line-chart-container">
                {lineChartData ? (
                  <Line
                    id="lineChart"
                    data={lineChartData}
                    options={lineChartOptions}
                  />
                ) : (
                  <div className="spinner" style={{ marginTop: "50px" }}></div>
                )}
              </div>
            </div>
          </div>
          <div className="daily-attendance container">
            <div className="attendance-header">
              <p>Daily Attendance</p>
              <button onClick={toggleCalendar}>📅 Calendar</button>
            </div>
            <div className="week-dates">
              {currentWeekDates.map((date, index) => (
                <div
                  key={index}
                  className={`date-item ${
                    date.getDate() === selectedDate.getDate() ? "active" : ""
                  }`}
                  onClick={() => loadAttendanceForDate(date)}
                >
                  <b>
                    <p>{date.getDate()}</p>
                  </b>
                  <p className="date-item-days">{daysOfWeek[date.getDay()]}</p>
                </div>
              ))}
            </div>
            {renderAttendanceTable()}
            {showCalendar && (
              <div className="calendar-popup">
                <div className="calendar-header">
                  <input
                    type="text"
                    placeholder="Start date"
                    value={
                      startDate ? startDate.toLocaleDateString() : "Start date"
                    }
                    readOnly
                  />
                  <span> - </span>
                  <input
                    type="text"
                    placeholder="End date"
                    value={endDate ? endDate.toLocaleDateString() : "End date"}
                    readOnly
                  />
                </div>
                <Calendar
                  onClickDay={handleCalendarClick}
                  value={selectedDate}
                />
                <div className="calendar-footer">
                  <button
                    className="calendar-cancel-button"
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </button>
                  <button
                    className="calendar-apply-button"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
          isOpen={grantAccess}
          onRequestClose={closeOneTimeAccess}
          contentLabel="Grant One time Access"
           className="view-staff-modal"
        >
          <div>
          <div className="enter-password">
            <h2>Provide Access</h2>
          </div>
          <p>Enter member’s registered phone number to provide one-time access to the gym.</p>
          <form className="view-staff-form" onSubmit={handleAccessSubmit}>
            <label htmlFor="password">Member's Phone Number:</label>
            <br />
            <input
              type="number"
              id="number"
              name="number"
              value={number}
              required
              onChange={(e) => setNumber(e.target.value)}
              style={{fontSize:'14px'}}
            />
            <div className="modal-button">
              <button type="button" onClick={() => {setGrantAccess(false); setNumber('')}}>
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
          isOpen={AccessSuccess}
          onRequestClose={AccessSuccessIsClosed}
          contentLabel="Success Modal"
          className="edit-success-modal"
        >
          <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
          <h2 style={{fontWeight:"bolder"}}>Clock-in Success</h2>
          <div className="qr-notif-box" style={{marginTop:"10px"}}>
          <img
                  src={
                    clockInDetails.profile_image
                      ? clockInDetails.profile_image
                      : `${process.env.PUBLIC_URL}/images/settings-avatar.png`
                  }
                  alt="Profile"
                />
                <div className="qr-notif-details">
                  <p>
                    <span className="notif-span">Name:</span> {clockInDetails.f_name} {clockInDetails.l_name}
                  </p>
                  <p>
                    <span className="notif-span">Gym Number:</span>#{clockInDetails.id}
                  </p>
                </div>
              </div>
          <button onClick={() =>{AccessSuccessIsClosed(); window.location.reload()}}>Close</button>
        </Modal>

        <Modal
          isOpen={AccessFailure}
          onRequestClose={AccessFailureIsClosed}
          contentLabel="Error Modal"
          className="edit-success-modal clock-in-failure"
        >
          <img src={`${process.env.PUBLIC_URL}/images/delete-danger.png`} alt="Error" />
         <h2 style={{fontWeight:"bolder"}}>{clockInError}</h2>

          {clockInError === "user not found" && (
            <p style={{fontSize:'16px', color:'#737373', marginTop:"15px"}}>User is not registered yet. Kindly, register user to gain access to the gym.</p>
          )}

          {clockInError === "user not found" ? (
            <div className="clock-in-btns">
              <button onClick={AccessFailureIsClosed}>Cancel</button>
              <Link to="/member-management">
                <button onClick={AccessFailureIsClosed} style={{color:'white', backgroundColor:'#0473bd'}}>Register User</button>
              </Link>
            </div>
          ) : (
            <button onClick={AccessFailureIsClosed} style={{width:'350px', marginBottom:'24px'}}>Cancel</button>
          )}
        </Modal>

    </div>
  );
}

export default Dashboard;