import React, { useState, useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear(); 
    sessionStorage.clear(); 
  }, []); 

  useEffect(() => {
    return () => {
      setEmail(""); 
      setPassword(""); 
    };
  }, []); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); 

    try {
      const response = await axios.post("/super-admin/login", {
        email,
        password,
      });

      if (response.data.type === "success") {
        const token = response.data.message.token; // Assuming the token is in response.data.message.token
        localStorage.setItem("accessToken", token); // Store token in local storage
        navigate("/dashboard"); // Navigate to dashboard upon successful login
        setEmail(""); // Clear email input
        setPassword(""); // Clear password input
      } else {
        alert("Invalid email or password");
      }
    } catch (error) {
      console.error("There was a problem with the login request:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
      } else {
        alert("Failed to login. Please try again later.");
      }
    } finally {
      setLoading(false); 
    }
  };

  return (
    <div className="login">
      <div className="login-input">
        <div className="login-input-main container">
          <h1>Welcome back</h1>
          <p>Please enter your details</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <b>
                <label htmlFor="email">Email</label>
              </b>
              <br />
              <input
                type="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Enter your email"
                disabled={loading} 
                autoComplete="email"
              />
            </div>
            <br />
            <div className="form-group">
              <b>
                <label htmlFor="password">Password</label>
              </b>
              <br />
              <input
                type="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                placeholder="Enter your password"
                disabled={loading} 
                autoComplete="password"
              />
              <br />
              <Link to="/request-otp" className="forgot-password">
                Forgot password?
              </Link>
            </div>
            <br />
            <button
              type="submit"
              className="signin-button"
              disabled={loading} 
            >
              {loading ? <div className="login-spinner"></div> : "Sign In"}
            </button>
          </form>
        </div>
      </div>

      <div className="login-image1">
        {/* Optional image or any additional content */}
      </div>
    </div>
  );
}

export default Login;
