import React, { useState, useMemo, useEffect, createRef } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import Modal from "react-modal";
import { Parser } from "json2csv";
import { format, parseISO } from "date-fns";

Modal.setAppElement("#root");

function StaffManagement() {
  const popupRef = createRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deleteSuccessModalIsOpen, setDeleteSuccessModalIsOpen] =
    useState(false);
  const [disableSuccessModalIsOpen, setDisableSuccessModalIsOpen] =
    useState(false);
  const [enableSuccessModalIsOpen, setEnableSuccessModalIsOpen] =
    useState(false);
  const [editSuccessModalIsOpen, setEditSuccessModalIsOpen] = useState(false);
  const [optionsPopupOpen, setOptionsPopupOpen] = useState(null);
  const [passwordSuccessModalIsOpen, setPasswordSuccessModalIsOpen] =
    useState(false);
  const [viewStaffInfo, setViewStaffInfo] = useState(null);
  const adminData = sessionStorage.getItem("adminName");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = useMemo(() => localStorage.getItem("accessToken"), []);

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL: "/super-admin",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }),
    [token]
  );
  const [formData, setFormData] = useState({
    f_name: "",
    l_name: "",
    address: "",
    email: "",
    phone: "",
    nin: "",
    role_id: "2", // Default role_id, assuming "3" corresponds to "Admin"
  });
  const [admins, setAdmins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Adjusted to limit 8 items per page
  const [loginId, setLoginId] = useState(""); // Define loginId state
  const [password, setPassword] = useState(""); // Define password state
  const [searchTerm, setSearchTerm] = useState(""); // Define searchTerm state
  const [selectedAdmin, setSelectedAdmin] = useState(null); // Define selectedAdmin state
  const [adminToDeleteId, setAdminToDeleteId] = useState(null); // Define adminToDeleteId state
  const [passwordInput, setPasswordInput] = useState(""); // Add password input state
  const [disableModalIsOpen, setDisableModalIsOpen] = useState(false);
  const [disableUserId, setDisableUserId] = useState(null);
  const [enableModalIsOpen, setEnableModalIsOpen] = useState(false);
  const formattedDate = useMemo(() => {
    const date = new Date();
    return format(date, "EEE, MMMM dd");
  }, []);

  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/get-admins`);
        setAdmins(response.data.message.data);
      } catch (error) {
        console.error(
          "Error fetching admins:",
          error.response ? error.response.data : error.message
        );
      } finally {
        setLoading(false);
      }
    };
    fetchAdmins();
  }, [axiosInstance]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "profile_image") {
      const file = files[0];
      setSelectedImage(file);
      setImagePreviewUrl(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("f_name", formData.f_name);
      formDataToSend.append("l_name", formData.l_name);
      formDataToSend.append("address", formData.address);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("nin", formData.nin);
      formDataToSend.append("role_id", formData.role_id);
      if (selectedImage) {
        formDataToSend.append("profile_image", selectedImage);
      }

      const response = await axiosInstance.post(
        "/create-admin-account",
        formDataToSend
      );
      setLoginId(response.data.message.login_id);
      setPassword(response.data.message.password);
      setFormData({
        f_name: "",
        l_name: "",
        address: "",
        email: "",
        phone: "",
        nin: "",
        role_id: "",
      });
      setSelectedImage(null);
      setImagePreviewUrl(null);
      setModalIsOpen(false);
      setSuccessModalIsOpen(true);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      console.error("Error submitting form:", errorMessage);
      alert(errorMessage);
    }
  };

  const handleShare = () => {
    const text = `Login ID: ${loginId}\nPassword: ${password}`;
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "staff_credentials.txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleShareDetails = () => {
    if (viewStaffInfo) {
      const text = `
      First Name: ${selectedAdmin.f_name}\n
      Last Name: ${selectedAdmin.l_name}\n
      Email: ${viewStaffInfo.email}\n
      Phone Number: ${viewStaffInfo.phone}\n
      Home Address: ${viewStaffInfo.address}\n
      National ID: ${viewStaffInfo.nin}\n
      Role: ${viewStaffInfo.role_id}\n
      Password: ${viewStaffInfo.plain_password}
    `;
      const blob = new Blob([text], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "staff_details.txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const handleExportCSV = () => {
    if (admins.length === 0) {
      alert("No data available to export");
      return;
    }

    const fields = [
      "f_name",
      "l_name",
      "email",
      "role_id",
      "created_at",
      "last_seen",
      "access_status",
    ];
    const opts = { fields };
    const parser = new Parser(opts);
    const csv = parser.parse(admins);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "admins.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const goToNextPage = () => {
    if (currentPage < Math.ceil(filteredAdmins.length / itemsPerPage))
      setCurrentPage((prevPage) => prevPage + 1);
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prevPage) => prevPage - 1);
  };
  const debounceSearch = useMemo(() => {
    const delaySearch = setTimeout(() => {
      setCurrentPage(1);
    }, 300);
    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  useEffect(() => {
    debounceSearch();
  }, [searchTerm, debounceSearch]);

  const filteredAdmins = useMemo(() => {
    return admins.filter((admin) => {
      const fName = admin.f_name ? admin.f_name.toLowerCase() : "";
      const lName = admin.l_name ? admin.l_name.toLowerCase() : "";
      const email = admin.email ? admin.email.toLowerCase() : "";
      return (
        fName.includes(searchTerm.toLowerCase()) ||
        lName.includes(searchTerm.toLowerCase()) ||
        email.includes(searchTerm.toLowerCase())
      );
    });
  }, [admins, searchTerm]);

  const openEditModal = (admin) => {
    setSelectedAdmin(admin);
    setFormData({
      f_name: admin.f_name,
      l_name: admin.l_name,
      address: admin.address,
      email: admin.email,
      phone: admin.phone,
      nin: admin.nin,
      role_id: admin.role_id,
    });
    setEditModalIsOpen(true);
    setOptionsPopupOpen(null);
  };

  const openDeleteModal = (admin) => {
    setSelectedAdmin(admin);
    setAdminToDeleteId(admin.id);
    setDeleteModalIsOpen(true);
    setOptionsPopupOpen(null);
  };

  const openOptionsPopup = (index) => {
    setOptionsPopupOpen(optionsPopupOpen === index ? null : index);
  };

  const closeOptionsPopup = () => {
    setOptionsPopupOpen(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closeOptionsPopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  const openDisableModal = (admin) => {
    setSelectedAdmin(admin);
    setDisableUserId(admin.id); // Set the user ID to disable
    setDisableModalIsOpen(true);
    setOptionsPopupOpen(null);
  };

  const handleDisableUser = async () => {
    try {
      if (!disableUserId) {
        console.error("User ID to disable is not set.");
        return;
      }

      const response = await axiosInstance.post(`/disable-user-access`, {
        user_id: disableUserId,
      });

      setDisableModalIsOpen(false);
      setDisableSuccessModalIsOpen(true);
    } catch (error) {
      console.error(
        "Error disabling user:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to disable user");
    }
  };

  const openEnableModal = (admin) => {
    setSelectedAdmin(admin);
    setEnableModalIsOpen(true);
    setOptionsPopupOpen(null);
  };

  const handleEnableUser = async () => {
    try {
      if (!selectedAdmin || !selectedAdmin.id) {
        console.error("User ID to enable is not set.");
        return;
      }

      const response = await axiosInstance.post(`/enable-user-access`, {
        user_id: selectedAdmin.id,
      });

      setEnableModalIsOpen(false);
      setEnableSuccessModalIsOpen(true);
    } catch (error) {
      console.error(
        "Error enabling user:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to enable user");
    }
  };

  const handleDeleteAdmin = async () => {
    try {
      if (!selectedAdmin || !selectedAdmin.id) {
        console.error("Admin ID to delete is not set.");
        return;
      }
      // Assuming 'selectedAdmin.id' is the 'user_id' needed for deletion
      const response = await axiosInstance.post(`/delete-user`, {
        user_id: selectedAdmin.id,
      });

      const updatedAdmins = admins.filter(
        (admin) => admin.id !== selectedAdmin.id
      );
      setAdmins(updatedAdmins);
      setDeleteModalIsOpen(false);
      setDeleteSuccessModalIsOpen(true);
    } catch (error) {
      console.error(
        "Error deleting admin:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedAdmin || !selectedAdmin.id) {
        console.error("Selected admin or admin ID is missing.");
        return;
      }

      // Construct updatedData with all fields, prioritizing formData
      const updatedData = {
        user_id: selectedAdmin.id,
        f_name:
          formData.f_name === undefined
            ? selectedAdmin.f_name
            : formData.f_name,
        l_name:
          formData.l_name === undefined
            ? selectedAdmin.l_name
            : formData.l_name,
        address:
          formData.address === undefined
            ? selectedAdmin.address
            : formData.address,
        email:
          formData.email === undefined ? selectedAdmin.email : formData.email,
        phone:
          formData.phone === undefined ? selectedAdmin.phone : formData.phone,
        nin: formData.nin === undefined ? selectedAdmin.nin : formData.nin,
        role_id:
          formData.role_id === undefined
            ? selectedAdmin.role_id
            : formData.role_id,
      };

      const response = await axiosInstance.post(
        "/update-user-details",
        updatedData
      );

      // Update admins state to reflect the changes
      const updatedAdmins = admins.map((admin) =>
        admin.id === selectedAdmin.id ? { ...admin, ...updatedData } : admin
      );
      setAdmins(updatedAdmins);

      setEditModalIsOpen(false);
      setEditSuccessModalIsOpen(true); // Open the success modal
    } catch (error) {
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      console.error("Error updating user details:", errorMessage);
      alert(errorMessage);
    }
  };

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/view-staff-info", {
        staff_id: selectedAdmin.id,
        password: passwordInput,
      });
      const staffInfo = response.data.message.staff;
      setViewStaffInfo(staffInfo); // Set the viewStaffInfo state with the response data
      setViewModalIsOpen(false);
      setPasswordSuccessModalIsOpen(true); // Open the success modal
      setPasswordInput('');
    } catch (error) {
      console.error(
        "Error validating password:",
        error.response ? error.response.data : error.message
      );
      alert("Invalid password");
    }
  };

  const openViewModal = (admin) => {
    setSelectedAdmin(admin);
    setViewModalIsOpen(true);
    setOptionsPopupOpen(null);
  };

  const paginatedAdmins = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredAdmins.slice(startIndex, endIndex);
  }, [filteredAdmins, currentPage, itemsPerPage]);

  return (
    <div className="dashboard">
      <div className="Nav">
        <Sidebar />
      </div>
      <div className="dashboard-display">
        <div className="top-bar container-fluid">
          <div className="top-bar-info">
            <p id="date">{formattedDate}</p>
            <b>
              <p>Welcome back {adminData}</p>
            </b>
          </div>
          <div className="top-bar-button">
            <button onClick={() => setModalIsOpen(true)}>
              <img
                src={`${process.env.PUBLIC_URL}/images/user-add.png`}
                alt=""
              />{" "}
              Add Staff
            </button>
          </div>
        </div>

        <div className="main-settings staff-items">
          <div className="search">
            <input
              type="text"
              placeholder="Search fields"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="export">
            <button onClick={handleExportCSV}>
              <img
                src={`${process.env.PUBLIC_URL}/images/export-icon.png`}
                alt=""
              />{" "}
              Export
            </button>
          </div>
        </div>

        <div className="show-admin-table">
          <table className="admin-table">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Date Created</th>
                <th>Last Active</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="8">
                    <div className="spinner"></div>
                  </td>
                </tr>
              ) : (
                paginatedAdmins.map((item, index) => {
                  const serialNumber =
                    (currentPage - 1) * itemsPerPage + index + 1;
                  return (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>{`${item.f_name} ${item.l_name}`}</td>
                      <td>{item.email}</td>
                      <td>{item.role_id === 2 ? "Admin" : "Super-Admin"}</td>
                      <td>
                        {item.created_at
                          ? format(parseISO(item.created_at), "dd MMM yyyy")
                          : "N/A"}
                      </td>
                      <td>
                        {item.last_seen
                          ? format(parseISO(item.last_seen), "dd MMM yyyy")
                          : "N/A"}
                      </td>
                      <td
                        style={{
                          color: item.access_status === 1 ? "green" : "red",
                          fontWeight: "700",
                        }}
                      >
                        {item.access_status === 1 ? "Active" : "Inactive"}
                      </td>
                      <td>
                        <div className="options-menu">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/horizontal-menu.png`}
                            onClick={() => openOptionsPopup(index)}
                          />
                          {optionsPopupOpen === index && (
                            <div className="popup" ref={popupRef}>
                              <ul>
                                <li onClick={() => openEditModal(item)}>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/staff-edit.png`}
                                  />{" "}
                                  Edit Staff Profile
                                </li>
                                <li onClick={() => openViewModal(item)}>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/staff-view.png`}
                                  />{" "}
                                  View Staff Profile
                                </li>
                                {item.access_status === 0 && (
                                  <li onClick={() => openEnableModal(item)}>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/enable-user.png`}
                                    />{" "}
                                    Enable Staff Profile
                                  </li>
                                )}

                                {item.access_status === 1 && (
                                  <li
                                    style={{ color: "#DD9828" }}
                                    onClick={() => openDisableModal(item)}
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/disable-user.png`}
                                    />{" "}
                                    Disable Staff Profile
                                  </li>
                                )}

                                <li
                                  style={{ color: "red" }}
                                  onClick={() => openDeleteModal(item)}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/staff-delete.png`}
                                  />{" "}
                                  Delete Profile
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>

          <div className="pagination staff-pagination">
            <span>{`Page ${currentPage} of ${Math.ceil(
              filteredAdmins.length / itemsPerPage
            )}`}</span>
            <div>
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                Previous
              </button>
              <button
                onClick={goToNextPage}
                disabled={
                  currentPage ===
                  Math.ceil(filteredAdmins.length / itemsPerPage)
                }
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Add Staff Modal"
        className="add-admin-modal"
      >
        <div className="add-staff-head">
          <h2>Staff details</h2>
        </div>

        <div className="add-staff-image">
          <div className="add-staff-image-pic">
            {imagePreviewUrl ? (
              <img
                src={imagePreviewUrl}
                alt="Selected"
                width="36px"
                height="36px"
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/upload-img.png`}
                alt=""
                width="36px"
                height="36px"
              />
            )}
            <div className="add-staff-image-text">
              <p>Upload staff's picture</p>
              <p>PNG OR JPG</p>
            </div>
          </div>
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            id="upload-picture"
            name="profile_image"
            onChange={handleChange}
            style={{ display: "none" }}
          />
          <label htmlFor="upload-picture" style={{ cursor: "pointer" }}>
            Upload picture
          </label>
        </div>

        <form onSubmit={handleSubmit} className="staff-form">
          <div className="inner-form">
            <label htmlFor="f_name">First Name:</label>
            <br />
            <input
              type="text"
              id="f_name"
              name="f_name"
              value={formData.f_name}
              onChange={handleChange}
              required
            />

            <label htmlFor="l_name">Last Name:</label>
            <br />
            <input
              type="text"
              id="l_name"
              name="l_name"
              value={formData.l_name}
              onChange={handleChange}
              required
            />

            <label htmlFor="address">Address:</label>
            <br />
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email:</label>
            <br />
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Phone Number:</label>
            <br />
            <input
              type="tel"
              id="phone"
              name="phone"
              pattern="[0-9]{11}"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            <label htmlFor="nin">National Identification Number:</label>
            <br />
            <input
              type="text"
              id="nin"
              name="nin"
              value={formData.nin}
              onChange={handleChange}
              required
            />

            <label htmlFor="role_id">Role:</label>
            <br />
            <select
              id="role_id"
              name="role_id"
              value={formData.role_id}
              onChange={handleChange}
              required
            >
              <option value="2">Admin</option>
              <option value="3">Super Admin</option>
            </select>
          </div>
          <div className="modal-button">
            <button type="button" onClick={() => setModalIsOpen(false)}>
              Cancel
            </button>
            <input type="submit" value="Submit" />
          </div>
        </form>
      </Modal>

      <Modal
        isOpen={successModalIsOpen}
        onRequestClose={() => setSuccessModalIsOpen(false)}
        contentLabel="Success Modal"
        className="add-admin-modal2"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2>Successful</h2>
        <p>Staff has been added successfully.</p>
        <div className="details-container container" style={{width:'100%'}}>
          <div className="detail">
            <p>Login ID</p>
            <b>
              <p className="D">{loginId}</p>
            </b>
          </div>
          <div className="detail">
            <p>Password</p>
            <b>
              <p className="D">{password}</p>
            </b>
          </div>
        </div>
        <button type="button" onClick={handleShare}>
          Share
        </button>
        <button
        type="button"
        onClick={() => {
          setSuccessModalIsOpen(false);
          window.location.reload(); // Refresh the page
        }}
      >
        Back
      </button>
      </Modal>

      <Modal
        isOpen={viewModalIsOpen}
        onRequestClose={() => setViewModalIsOpen(false)}
        contentLabel="View Admin Modal"
        className="view-staff-modal"
      >
        {selectedAdmin && (
          <div>
            <div className="enter-password">
              <h2>Enter Password</h2>
            </div>

            <p>Enter super admin password to view staff's details</p>
            <form onSubmit={handlePasswordSubmit} className="view-staff-form">
              <label htmlFor="password">Password:</label>
              <br />
              <input
                type="password"
                id="password"
                name="password"
                value={passwordInput}
                onChange={handlePasswordChange}
                required
                autoComplete="off"
              />
              <div className="modal-button">
                <button type="button" onClick={() => setViewModalIsOpen(false)}>
                  Back
                </button>
                <button type="submit">View Details</button>
              </div>
            </form>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => setEditModalIsOpen(false)}
        contentLabel="Edit Admin Modal"
        className="add-admin-modal"
      >
        {selectedAdmin && (
          <div>
            <div className="add-staff-head">
              <h2>Edit staff details</h2>
            </div>
            <form onSubmit={handleEditSubmit} className="staff-form">
              <div className="inner-form">
                <label htmlFor="f_name">First Name:</label>
                <br />
                <input
                  type="text"
                  id="f_name"
                  name="f_name"
                  value={formData.f_name}
                  onChange={handleChange}
                />
                <label htmlFor="l_name">Last Name:</label>
                <br />
                <input
                  type="text"
                  id="l_name"
                  name="l_name"
                  value={formData.l_name}
                  onChange={handleChange}
                />
                <label htmlFor="address">Address:</label>
                <br />
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
                <label htmlFor="email">Email:</label>
                <br />
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <label htmlFor="phone">Phone Number:</label>
                <br />
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  pattern="[0-9]{11}"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <label htmlFor="nin">National Identification Number:</label>
                <br />
                <input
                  type="text"
                  id="nin"
                  name="nin"
                  value={formData.nin}
                  onChange={handleChange}
                />
                <label htmlFor="role_id">Role:</label>
                <br />
                <select
                  id="role_id"
                  name="role_id"
                  value={formData.role_id}
                  onChange={handleChange}
                >
                  <option value="2">Admin</option>
                  <option value="3">Super Admin</option>
                </select>
              </div>
              <div className="modal-button">
                <button type="button" onClick={() => setEditModalIsOpen(false)}>
                  Cancel
                </button>
                <input type="submit" value="Save Update" />
              </div>
            </form>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        contentLabel="Delete Admin Modal"
        className="delete-modal"
      >
        {selectedAdmin && (
          <div className="delete-modal-inner">
            <img
              src={`${process.env.PUBLIC_URL}/images/delete-danger.png`}
              alt=""
            />
            <p>Are you sure you want to delete this Staff profile?</p>
            <div className="delete-modal-button">
              <button className="delete-btn1" onClick={handleDeleteAdmin}>
                Delete
              </button>
              <button
                className="delete-btn2"
                onClick={() => setDeleteModalIsOpen(false)}
              >
                Back
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={deleteSuccessModalIsOpen}
        onRequestClose={() => setDeleteSuccessModalIsOpen(false)}
        contentLabel="Delete Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2>User Profile Deleted</h2>
        <p>Staff profile has been deleted successfully</p>
        <button
          type="button"
          onClick={() => setDeleteSuccessModalIsOpen(false)}
        >
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={passwordSuccessModalIsOpen}
        onRequestClose={() => setPasswordSuccessModalIsOpen(false)}
        contentLabel="Password Success Modal"
        className="view-info-modal"
      >
        {viewStaffInfo && (
          <div className="view-info-inner">
            <div className="add-staff-head view-info-head">
              <h2>Staff Info</h2>
            </div>
            <div className="view-staff-name">
              <img
                src={`${process.env.PUBLIC_URL}/images/admin-img.png`}
                alt=""
              />

              <p className="D">{selectedAdmin.f_name}</p>
              <p className="D">{selectedAdmin.l_name}</p>
            </div>
            <div className="details-container container">
              <div className="detail">
                <p>Email</p>

                <p className="D">{viewStaffInfo.email}</p>
              </div>
              <div className="detail">
                <p>Phone Number</p>

                <p className="D">{viewStaffInfo.phone}</p>
              </div>
              <div className="detail" style={{ marginBottom: "10px" }}>
                <p>Home Address</p>

                <p className="D">{viewStaffInfo.address}</p>
              </div>
              <div className="detail">
                <p>NIN</p>

                <p className="D">{viewStaffInfo.nin}</p>
              </div>
              <div className="detail">
                <p>Role</p>

                <p className="D">
                  {viewStaffInfo.role_id === 2
                    ? "Admin"
                    : viewStaffInfo.role_id === 3
                    ? "Super Admin"
                    : "Unknown Role"}
                </p>
              </div>
              <div className="detail">
                <p>Password</p>

                <p className="D" style={{ color: "#0473bd", fontSize: "18px" }}>
                  {viewStaffInfo.plain_password}
                </p>
              </div>
            </div>
            <div className="view-info-button">
              <button onClick={handleShareDetails}>Share</button>
              <button
                type="button"
                onClick={() => setPasswordSuccessModalIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={editSuccessModalIsOpen}
        onRequestClose={() => setEditSuccessModalIsOpen(false)}
        contentLabel="Edit Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2>Successful</h2>
        <p>Staff profile has successfully been updated</p>
        <button type="button" onClick={() => setEditSuccessModalIsOpen(false)}>
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={enableModalIsOpen}
        onRequestClose={() => setEnableModalIsOpen(false)}
        contentLabel="Enable Admin Modal"
        className="delete-modal"
      >
        {selectedAdmin && (
          <div className="delete-modal-inner">
            <img
              src={`${process.env.PUBLIC_URL}/images/enable-modal-img.png`}
              alt=""
            />
            <p>Are you sure you want to enable this Staff profile?</p>
            <div className="delete-modal-button">
              <button
                className="delete-btn1"
                style={{ backgroundColor: "#0473DB" }}
                onClick={handleEnableUser}
              >
                Enable
              </button>
              <button
                className="delete-btn2"
                onClick={() => setEnableModalIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={enableSuccessModalIsOpen}
        onRequestClose={() => setEnableSuccessModalIsOpen(false)}
        contentLabel="Enable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2>User Profile Enabled</h2>
        <p>Staff profile has been Enabled</p>
        <button
          type="button"
          onClick={() => setEnableSuccessModalIsOpen(false)}
        >
          Cancel
        </button>
      </Modal>

      <Modal
        isOpen={disableModalIsOpen}
        onRequestClose={() => setDisableModalIsOpen(false)}
        contentLabel="Disable Admin Modal"
        className="delete-modal"
      >
        {selectedAdmin && (
          <div className="delete-modal-inner">
            <img
              src={`${process.env.PUBLIC_URL}/images/disable-modal-img.png`}
              alt=""
            />
            <p>Are you sure you want to disable this Staff profile?</p>
            <div className="delete-modal-button">
              <button
                style={{ backgroundColor: "#DD9828", color: "#FAFAFA" }}
                className="delete-btn1"
                onClick={handleDisableUser}
              >
                Disable
              </button>
              <button
                className="delete-btn2"
                onClick={() => setDisableModalIsOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>

      <Modal
        isOpen={disableSuccessModalIsOpen}
        onRequestClose={() => setDisableSuccessModalIsOpen(false)}
        contentLabel="Disable Success Modal"
        className="edit-success-modal"
      >
        <img src={`${process.env.PUBLIC_URL}/images/thumbs-up.png`} alt="" />
        <h2>User Profile Disabled</h2>
        <p>Staff profile has been Disabled</p>
        <button
          type="button"
          onClick={() => setDisableSuccessModalIsOpen(false)}
        >
          Cancel
        </button>
      </Modal>
    </div>
  );
}

export default StaffManagement;
