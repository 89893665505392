import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function InputOTP() {
  const [values, setValues] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [incorrectOtp, setIncorrectOtp] = useState(false);
  const [correctOtp, setCorrectOtp] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleChange = (e, index) => {
    const newValue = e.target.value;
    if (newValue.length <= 1) {
      const newValues = [...values];
      newValues[index] = newValue;
      setValues(newValues);

      // Focus the next input field if available
      if (newValue && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      // Check if all input fields are filled
      if (newValues.every((value) => value !== "")) {
        handleSubmit(newValues.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (otp) => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    try {
      const response = await axios.post(
        "/super-admin/validate-otp",
        {
          otp,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (response.data.type === "success") {
        setCorrectOtp(true);
        setTimeout(() => {
          localStorage.setItem("otp", otp); // Store OTP in local storage
          navigate("/reset-password");
        }, 300); // duration should match the border color change duration
      } else {
        setError(response.data.message || "Invalid OTP");
        setIncorrectOtp(true);
        setTimeout(() => {
          setIncorrectOtp(false);
          setValues(["", "", "", "", "", ""]);
          inputRefs.current[0].focus();
        }, 300); // duration should match shake animation duration
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setIncorrectOtp(true);
      setTimeout(() => {
        setIncorrectOtp(false);
        setValues(["", "", "", "", "", ""]);
        inputRefs.current[0].focus();
      }, 300); // duration should match shake animation duration
    }
  };

  const handleResendOTP = async () => {
    const email = localStorage.getItem("email");

    try {
      const response = await axios.post("/super-admin/reset-password/otp", {
        email,
      });

      if (response.data.type === "success") {
        setCountdown(60);
        alert("OTP has been resent successfully, check your mail");
      } else {
        alert("Failed to resend OTP. Please try again later.");
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Failed to resend OTP. Please try again later.");
    }
  };

  return (
    <div className="input-otp-container">
      <div className="input-otp-input">
        <div className="input-otp-input-main container">
          <h1>OTP Verification</h1>
          <p>Enter the 6-digit code we sent to your email address</p>
          <div className="segmented-input">
            {values.map((value, index) => (
              <input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                value={value}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength="1"
                className={`segment ${value ? "" : "empty"} ${
                  incorrectOtp ? "shake" : ""
                } ${correctOtp ? "correct" : ""}`}
              />
            ))}
          </div>
          <p className="otp-resend">
            {countdown > 0 ? (
              <>
                Didn’t get the code? Resend in{" "}
                <span
                  style={{
                    color: countdown <= 60 ? "#0473BD" : "inherit",
                    fontWeight: "bold",
                  }}
                >
                  {countdown} secs
                </span>
              </>
            ) : (
              <>
                Didn’t get the code?{" "}
                <span
                  onClick={handleResendOTP}
                  style={{
                    color: "#0473BD",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Resend
                </span>
              </>
            )}
          </p>
        </div>
      </div>
      <div className="login-image2">
        {/* Optional image or any additional content */}
      </div>
    </div>
  );
}

export default InputOTP;
